/* eslint-disable ember/no-runloop */
import Controller from '@ember/controller';
import { next } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import type { IntlService } from 'ember-intl';
import { modifier } from 'ember-modifier';

export default class ApplicationController extends Controller {
  @service declare api: Services['api'];
  @service declare notificationBanners: Services['notification-banners'];
  @service declare intl: IntlService;
  @service declare features: Services['features'];

  get shouldShowApiStatus() {
    return this.api.heavyLoad || this.api.outage;
  }

  get shouldShowOneTrustBanner(): boolean {
    return this.features.isEnabled('ONE_TRUST_BANNER');
  }

  showHeavyLoadBanner = modifier(() => {
    let banner:
      | ReturnType<Services['notification-banners']['show']>
      | undefined;

    next(() => {
      banner = this.notificationBanners.show(
        this.intl.t('api-error-page.heavy-load.message'),
        {
          appearance: 'warning',
        },
      );
    });

    return () => {
      // This is necessary to avoid flaky tests. It has been observed that the
      // application, along with this ApplicationController, is destroyed
      // before the banner is assigned a value.
      banner?.hide();
    };
  });
}
